import * as React from 'react';
import { Box, Stack, Typography } from "@mui/material";

type AnniversaryGiftProps = {
  year: string;
  gift: string;
};

export default function AnniversaryGift({ year, gift }: AnniversaryGiftProps): JSX.Element {
  return (
    <Stack direction="row" gap={2}>
      <Box width="3rem">
        <Typography
          variant="body1"
          textAlign="right"
          fontFamily="'Roboto Mono', monospace"
          fontSize="0.9rem"
        >
          {year}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1">{gift}</Typography>
      </Box>
    </Stack>
  );
}
