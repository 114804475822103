import * as React from 'react';
import Layout from '../../components/Layout';
import { Box, Container, Stack, Typography } from '@mui/material';
import Header from '../../components/Header';
import AnniversaryGift from '../../components/AnniversaryGift';
import Footer from '../../components/Footer';

export default function TraditionalAnniversaryGifts(): JSX.Element {
  return (
    <Layout title="Traditional Anniversary Gifts Guide | Oclee &amp; Son">
      <Stack minHeight="100vh">
        <Header page="guides" />
        <Box flex={1} mb={3}>
          <Container>
            <Typography variant="h1" gutterBottom>Traditional Anniversary Gifts</Typography>
            <Typography variant="body1" mb={4}>
              The symbolic gifts associated with anniversaries have changed over
              time and vary from country to country. The following list shows
              gemstones and metals most commonly associated with anniversaries in
              the United Kingdom.
            </Typography>
          </Container>
          <Stack direction="row" justifyContent="center" mb={2}>
            <Stack direction="column" gap={1}>
              <AnniversaryGift year="1st" gift="Freshwater pearl" />
              <AnniversaryGift year="2nd" gift="Garnet/Rose Quartz" />
              <AnniversaryGift year="3rd" gift="Crystal/Jade/Pearl" />
              <AnniversaryGift year="4th" gift="Topaz" />
              <AnniversaryGift year="5th" gift="Turquoise/Pink Tourmaline" />
              <AnniversaryGift year="6th" gift="Steel/Garnet/Amethyst" />
              <AnniversaryGift year="7th" gift="Copper/Onyx/Lapis Lazuli" />
              <AnniversaryGift year="8th" gift="Bronze/Tourmaline/Aventurine" />
              <AnniversaryGift year="9th" gift="Lapis Lazuli/Tiger Eye" />
              <AnniversaryGift year="10th" gift="Aluminium/Onyx" />
              <AnniversaryGift year="11th" gift="Steel/Turquoise/Hematite" />
              <AnniversaryGift year="12th" gift="Agate/Jade" />
              <AnniversaryGift year="13th" gift="Citrine/Malachite/Moonstone" />
              <AnniversaryGift year="14th" gift="Ivory/Moss Agate" />
              <AnniversaryGift year="15th" gift="Crystal" />
              <AnniversaryGift year="16th" gift="Topaz/Aquamarine/Peridot" />
              <AnniversaryGift year="17th" gift="Amethyst/Citrine" />
              <AnniversaryGift year="18th" gift="Garnet/Opal" />
              <AnniversaryGift year="19th" gift="Aquamarine/Topaz" />
              <AnniversaryGift year="20th" gift="China/Emerald" />
              <AnniversaryGift year="21st" gift="Brass/Iolite" />
              <AnniversaryGift year="22nd" gift="Copper" />
              <AnniversaryGift year="23rd" gift="Silver Plate" />
              <AnniversaryGift year="24th" gift="Tanzanite" />
              <AnniversaryGift year="25th" gift="Silver" />
              <AnniversaryGift year="30th" gift="Pearl" />
              <AnniversaryGift year="33rd" gift="Amethyst" />
              <AnniversaryGift year="34th" gift="Opal" />
              <AnniversaryGift year="35th" gift="Coral" />
              <AnniversaryGift year="40th" gift="Ruby" />
              <AnniversaryGift year="45th" gift="Sapphire/Alexandrite" />
              <AnniversaryGift year="50th" gift="Gold" />
              <AnniversaryGift year="55th" gift="Emerald" />
              <AnniversaryGift year="60th" gift="Diamond" />
              <AnniversaryGift year="65th" gift="Blue Sapphire" />
              <AnniversaryGift year="70th" gift="Platinum" />
            </Stack>
          </Stack>
        </Box>
        <Footer />
      </Stack>
    </Layout>
  );
}
